<template>
  <v-app >
<v-main >
      <router-view  />
    </v-main>
    <AppFooter v-if="$route.name != 'login'" />
  </v-app>
</template>

<script>
import "./assets/style/font.css";
import "./assets/style/style.css";
import store from "./store";
import axios from "axios";
import AppFooter from "./components/Common/appFooter";
export default {
  components: {
    AppFooter,
  },
  name: 'App',
  data: () => ({
    //
  }),
  beforeMount(){
    this.getData()
  },
  methods:{
    getData(){
      axios({
        method: "GET",
        url: "/admin/profile",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            store.commit("userData", response.data.data);
            store.commit("userType", response.data.userdata.role);

          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
}
</script>
